import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { checkToken } from "@onlinesales-ai/util-methods-v2";
import { fetchUser } from "@onlinesales-ai/app-v2/application";
import { fetchUserSettingMappings as fetchUserSettingMappingsAction } from "@onlinesales-ai/app-v2/setting";
import { fetchBillingSettings as fetchBillingSettingsAction } from "@onlinesales-ai/finance-v2/store/finance";

import { routes } from "src/utilities/constants";
import {
  redirectUrl,
  checkAgencyAccess,
} from "src/store/application";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

const INFRA_ADMIN_ID = 4661;
const AppAndAuthData = ({
  fetchUserData,
  redirectUrlTo,
  checkAgencyAccessData,
  userInfo,
  location,
  fetchUserSettingMappings,
  isShowPlanBadge,
  clientId,
  fetchBillingSettings,
  shouldFetchBillingSettingsData,
}) => {
  const fetchInitData = async () => {
    const isAuthenticated = checkToken();
    if (isAuthenticated) {
      await fetchUserData(routes, redirectUrl);
      if (isShowPlanBadge) {
        await fetchUserSettingMappings("MONETIZE_PULSE", true);
      }
      checkAgencyAccessData();
    } else {
      redirectUrlTo(routes.LOGIN.path);
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  useEffect(() => {
    const isAuthenticated = checkToken();

    if (!isAuthenticated) {
      redirectUrlTo(routes.LOGIN.path);
    }
  }, [location.pathname]);

  useEffect(() => {
    const { id } = userInfo || {};
    if (id && id === INFRA_ADMIN_ID) {
      PlatformEventManager.emit("INVALID_UA_TOKEN");
    }
  }, [userInfo]);

  useEffect(() => {
    if (shouldFetchBillingSettingsData) {
      fetchBillingSettings({ clientId });
    }
  }, [clientId, shouldFetchBillingSettingsData]);

  return null;
};

const mapStateToProps = (state) => {
  const { agencyId, userInfo, clientId } = state.Application;
  const { isShowPlanBadge, shouldFetchBillingSettingsData } = state.DomainConfig || {};

  return {
    agencyId,
    userInfo,
    location: state.router?.location || {},
    isShowPlanBadge,
    shouldFetchBillingSettingsData,
    clientId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserData: fetchUser,
      checkAgencyAccessData: checkAgencyAccess,
      redirectUrlTo: redirectUrl,
      fetchUserSettingMappings: fetchUserSettingMappingsAction,
      fetchBillingSettings: fetchBillingSettingsAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAndAuthData);
