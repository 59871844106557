import React, { lazy, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Router } from "@onlinesales-ai/app-v2";

import { Downtime } from "@onlinesales-ai/downtime-v2";
import { RouteImageWrapper } from "@onlinesales-ai/utils-components-v2";
import { trackUIDashboardLoad } from "@onlinesales-ai/tracking-reporting-v2";
import { useTimeBasedMonitor, triggerMonitor } from "@onlinesales-ai/error-catcher-v2";
import { GoogleLoginCallback } from "@onlinesales-ai/login-v2";

import AppHeader from "src/components/header";

import { routes } from "src/utilities/constants";
import { redirectUrl as redirectUrlAction } from "src/store/application";
import RouteBasedDrawer from "src/pages/routeBasedDrawer";
import ContextBasedDrawer from "src/pages/routeBasedDrawer/contextDrawer";
import SMMDashboard from "src/pages/smmDashboard";
import Login from "src/pages/login";
import AppAndAuthData from "src/app/AppAndAuthData";
import ClientLevelData from "src/app/ClientLevelData";

import AuthModal from "./AuthModal";

const NotificationProcessor = lazy(() => import("@onlinesales-ai/app-v2/src/notificationProcessor"));

const protectedRoutes = {
  [routes.SMM_DASHBOARD.path]: {
    component: SMMDashboard,
  },
  [routes.ACTION_PANEL_PAGE.path]: {
    component: lazy(() => import("src/pages/actionPanelPage")),
    exact: true,
  },
  [routes.ACTION_PANEL.path]: {
    component: lazy(() => import("src/pages/actionPanel")),
  },
  [routes.SELLER_KAM_DASHBOARD.path]: {
    component: lazy(() => import("src/pages/kamDashboard")),
  },
  [routes.BRAND_KAM_DASHBOARD.path]: {
    component: lazy(() => import("src/pages/kamDashboard")),
  },
  [routes.SKU_APPROVAL.path]: {
    component: lazy(() => import("src/pages/skuApproval")),
  },
  [routes.BULK_ACTION.path]: {
    component: lazy(() => import("src/pages/bulkAction")),
  },
  [routes.ACTIVITY_LOG.path]: {
    component: lazy(() => import("src/pages/activityLog")),
  },
  [routes.PLA_SELLER_MIS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PLA_GTM_PRIORITY.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PLA_COHORT.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PLA_SKU_ANALYTICS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PLA_AD_SERVER_ANALYTICS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PLA_REAL_TIME_REPORTING.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.DISPLAY_REAL_TIME_REPORTING.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.SELLER_MIS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.AD_SERVER_ANALYTICS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.GTM_PRIORITY.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.COHORT.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.INSIGHTS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.LEAD_MIS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PAID_LEAD_MIS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.SKU_ANALYTICS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PARTNER_HYGIENE.path]: {
    component: lazy(() => import("src/pages/partnerHygiene")),
  },
  [routes.CHANGE_HISTORY.path]: {
    component: lazy(() => import("src/components/changehistory/rollupWithApiAdmin")),
  },
  [routes.BULK_FEED_UPDATE.path]: {
    component: lazy(() => import("src/pages/iframeWrapper")),
  },
  [routes.INMAR_EVENT_BUILDER.path]: {
    component: RouteImageWrapper,
  },
  [routes.BRAND_ADS.path]: {
    component: lazy(() => import("src/pages/actionPanelPage/components/review/brandAdsReviewTable")),
  },
  [routes.DISPLAY_ADS_CAMPAIGN_REVIEW.path]: {
    component: lazy(() => import("src/pages/actionPanelPage/components/review/brandAdsReviewTable")),
  },
  [routes.DISPLAY_ADS_MERCHANT_INSIGHTS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.DISPLAY_ADS_INVENTORY_INSIGHTS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.DISPLAY_ADS_INVENTORY_CALENDAR_INSIGHTS.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PERFORMANCE.path]: {
    component: lazy(() => import("src/pages/metabase")),
  },
  [routes.PULSE.path]: {
    component: lazy(() => import("src/pages/pulse")),
    doNotRenderOutlet: true,
  },
  [routes.SUPPORT.path]: {
    component: lazy(() => import("src/pages/support")),
  },
  [routes.SUPPORT_TICKET_DETAIL_VIEW.path]: {
    component: lazy(() => import("src/pages/support/ticketDetailView")),
  },
};

const openRoutes = {
  [routes.LOGIN.path]: {
    component: Login,
  },
  [routes.DOWNTIME.path]: {
    component: Downtime,
  },
  [routes.ACCESS_DENIED.path]: {
    component: lazy(() => import("src/pages/accessDenied")),
  },
  [routes.GOOGLE_CALLBACK.path]: {
    component: GoogleLoginCallback,
  },
  [routes.FORGOT_PASSWORD.path]: {
    component: lazy(() => import("src/pages/forgotPassword")),
  },
  [routes.CREATE_PASSWORD.path]: {
    component: lazy(() => import("src/pages/createPassword")),
  },
  [routes.GOOGLE_SSO_CALLBACK.path]: {
    component: GoogleLoginCallback,
  },
};

const AuthAndClientComp = () => (
  <>
    <AppAndAuthData />
    <ClientLevelData />
  </>
);

const Routes = ({
  fetchUserList,
  fetchAppLevelData,
  userFetchError,
  redirectUrl,
  pathname,
  userInfo,
}) => {
  const isFirstTime = useRef(true);

  const isAppLoading = fetchUserList || fetchAppLevelData;
  const hasAppError = userFetchError;

  useEffect(() => {
    if (isFirstTime.current && !isAppLoading) {
      try {
        trackUIDashboardLoad();
      } catch (e) {}
      isFirstTime.current = false;
    }
  }, [isAppLoading]);

  useTimeBasedMonitor(
    {
      data: isAppLoading,
      checkCondition: () => !openRoutes?.[pathname] && !!userInfo?.id,
      dataShouldBe: false,
      dependancy: `${pathname}+${userInfo?.id}`,
    },
    10000,
    "SEV2",
    "APP_LEVEL_LOADING",
    {
      isAppLoading,
      fetchUserList,
      fetchAppLevelData,
    },
  );

  useEffect(() => {
    if (hasAppError) {
      triggerMonitor("SEV2", "APP_LEVEL_ERROR", {
        hasAppError,
        fetchUserList,
        fetchAppLevelData,
      });
    }
  }, [hasAppError]);

  return (
    <>
      <AuthModal />
      <Router
        routes={routes}
        isAppLoading={isAppLoading}
        hasAppError={hasAppError}
        openRoutes={openRoutes}
        protectedRoutes={protectedRoutes}
        redirectUrl={redirectUrl}
        components={{
          HEADER: AppHeader,
          AUTH_AND_CLIENT: AuthAndClientComp,
          ROUTE_BASED_DRAWER: RouteBasedDrawer,
          CONTEXT_BASED_DRAWER: ContextBasedDrawer,
          NOTIFICATION_PROCESSOR: NotificationProcessor,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.Application?.userInfo,
    fetchUserList: state.Application.fetchUserList,
    userFetchError: state.Application.userFetchError,
    fetchAppLevelData: state.Application.fetchAppLevelData,
    pathname: state.router?.location?.pathname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
