import React, { useEffect, useState } from "react";

import { UniversalDropdown } from "@onlinesales-ai/dropdown-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { Hades } from "@onlinesales-ai/services-v2";
import { SortAlphabeticalByKey, useControlState } from "@onlinesales-ai/util-methods-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";
import { connect } from "react-redux";

import "./index.less";

const ClientDropdown = ({
  value,
  onChange,
  label,
  placeholder,
  fetchState: pFetchState,
  setFetchState: pSetFetchState,
  agencyId,
  marketplaceClientId,
  showfullBorder,
  ...restProps
}) => {
  const [fetchState, setFetchState] = useControlState(pFetchState, pSetFetchState, {
    isFetchInProgress: false,
    fetchErrorMsg: null,
  });

  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    try {
      setFetchState({ isFetchInProgress: true, fetchErrorMsg: null });
      const payload = {
        agencyId,
        selectors: [
          "entityId",
          "entityName",
          "entityAlias",
          "entityType",
          "statusType",
          "depth",
          "metadata.businessDefinition",
          "hierarchy.entityId",
          "hierarchy.entityType",
          "hierarchy.entityName",
          "hierarchy.entityAlias",
        ],
        filters: [{ column: "statusType", operator: "EQUAL_TO", values: ["ACTIVE"] }],
        limit: 1000,
        offset: 0,
      };

      const hadesResponse = await Hades.getEntitiesBySearch(payload);

      const retnData = hadesResponse?.data
        .filter((data) => data.entityId !== marketplaceClientId)
        .map((data) => ({
          label: data?.entityAlias,
          value: data?.entityId,
          data,
        }));

      const sortedOptions = SortAlphabeticalByKey(retnData, "label");
      setOptions(sortedOptions);

      if ((sortedOptions || []).length > 0) {
        onChange(sortedOptions?.[0].value, sortedOptions?.[0]);
      }

      setFetchState({ isFetchInProgress: false, fetchErrorMsg: null });
    } catch (error) {
      uiAPIMonitor("SEV2", "APP_LEVEL_ENTITY_DATA_FAILED_CLIENT_DROPDOWN", {
        error,
        agencyId,
      });
      setFetchState({ isFetchInProgress: false, fetchErrorMsg: error?.errorMsg });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="client-dropdown d-align-center mb-2">
      <Text weight="semiBold">{`${label}:`}</Text>
      <UniversalDropdown
        value={value}
        onChange={(val, meta, original) => {
          onChange(val, original);
        }}
        isTranslateOptions={false}
        showfullBorder={showfullBorder}
        options={options}
        placeholder={placeholder}
        isLoading={fetchState.isFetchInProgress}
        isFetchError={fetchState.fetchErrorMsg}
        onReload={fetchData}
        {...restProps}
      />
    </div>
  );
};

ClientDropdown.defaultProps = {
  label: "Select ad account",
  placeholder: "Select Ad account",
};

const mapStateToProps = (state) => {
  const { agencyId, marketplaceClientId } = state.Application;

  return {
    agencyId,
    marketplaceClientId,
  };
};

export default connect(mapStateToProps)(ClientDropdown);
